iframe.formhero-iframe {
  z-index: 50;
}

#tweakpane {
  top: 60px;
  right: 0;
  font-size: 12px;
  color: #999;
  z-index: 89100;
  position: fixed;
  display: none;
}

#tweakpane .tp-lblv_v {
  width: 500px;
}

.tp-dfwv {
  z-index: 89999;
}
