.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500 px;
  background-color: white;
}

.banner img {
  height: 80%;
  object-fit: cover;
  margin-right:15px;
}